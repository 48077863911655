import React from 'react'
import { useNavigate } from 'react-router-dom';
import mainBg from '../Images/BrandingKit/mainBg.png';


function Partner() {
  const navigate = useNavigate();

  return (
    <div className="bg-[#191614] min-h-screen flex flex-col overflow-hidden relative">
      {/* <div className="absolute inset-0">
        <img
          src={mainBg}
          alt="BgImg"
          className="h-full w-full object-cover opacity-[0.07] bg-no-repeat"
        />
      </div> */}
      <div className="p-4 sm:p-6 border-b border-[#b58f49]/20 relative z-10">
        <button onClick={() => navigate('/')} className="text-[#b58f49] hover:text-[#c7a78a] transition-colors duration-300 mb-4 flex items-center group">
          <span className="mr-2 group-hover:-translate-x-1 transition-transform">←</span> Back to Home
        </button>
      </div>
      <div className="flex-grow flex flex-col items-center justify-center p-4 relative z-10">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className='text-[#b58f49] text-4xl sm:text-5xl md:text-6xl font-extrabold mb-6 animate-fade-in'>
            PARTNER PROGRAM
          </h1>
          <p className="text-[#d4b282] text-lg sm:text-xl mb-8 max-w-2xl mx-auto animate-fade-in animation-delay-300">
            We're preparing something extraordinary. Our partner program is designed to create meaningful collaborations that drive innovation and growth.
          </p>
          <button className="bg-[#b58f49] text-[#191614] px-8 py-3 rounded-full font-bold shadow-lg cursor-default">
            COMING SOON
          </button>
        </div>
      </div>
    </div>
  );
};

export default Partner;
