import React from 'react';
import mainLogo from '../Images/BrandingKit/mainLogo.png';

import { LuArrowRight, LuMapPin, LuMail } from 'react-icons/lu';
import { Link } from 'react-router-dom';

const Footer = ({ handleOpenModal }) => {
    return (
        <footer className="bg-[#191614] text-gray-200 ">
            <div className="w-full sm:px-6 lg:px-16 py-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-12 gap-8 lg:gap-8">
                    <div className="lg:col-span-3 flex items-center justify-center lg:justify-start">
                        <img
                            src={mainLogo}
                            alt="Lotus Glow Logo"
                            className="h-[100px] w-[80px] sm:h-[120px] sm:w-[100px]"
                        />
                    </div>
                    <div className="lg:col-span-3 text-center sm:text-left">
                        <h2 className="text-[#b58f49] font-bold text-lg mb-4 sm:mb-6">CONTACT US</h2>
                        <div className="space-y-3 sm:space-y-4">
                            <div className="flex items-center justify-center sm:justify-start">
                                <LuMapPin className="w-5 h-5 text-[#b58f49] flex-shrink-0" />
                                <p className="ml-3 text-sm">Guwahati, Assam</p>
                            </div>
                            <div className="flex items-center justify-center sm:justify-start">
                                <LuMail className="w-5 h-5 text-[#b58f49] flex-shrink-0" />
                                <p className="ml-3 text-sm">contact@jeevajyoti.org</p>
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-3 text-center sm:text-left">
                        <h2 className="text-[#b58f49] font-bold text-lg mb-4 sm:mb-6">OUR ASSOCIATION</h2>
                        <ul className="space-y-3">
                            <li>
                                <Link
                                    to="/association1"
                                    className="text-sm hover:text-[#b58f49] transition-colors duration-200"
                                >
                                    SPIRITUAL REGENERATION
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="lg:col-span-3 flex flex-col items-center justify-center">
                        <h2 className="text-[#b58f49] font-bold text-lg mb-4 sm:mb-6 text-center">FIND YOUR INNER LIGHT</h2>
                        <button
                            className="w-full sm:w-auto flex items-center mb-6 justify-center space-x-4 bg-[#b58f49] text-black hover:text-white py-3 px-8 rounded-full font-medium transition-all duration-300 ease-in-out hover:translate-x-1 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-[#b58f49] focus:ring-opacity-50"
                            onClick={handleOpenModal}
                        >
                            BOOK A SESSION!
                            <LuArrowRight className="w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform duration-200" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="border-t border-[#b58f49]/20">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
                    <p className="text-center text-xs sm:text-sm text-gray-400">
                        JEEVAJYOTI © ALL RIGHTS RESERVED 2024
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;