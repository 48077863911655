import Event1Main from '../../Images/Event1Main.jpg';
import Event1_1 from '../../Images/Event1_1.jpg';
import Event1_2 from '../../Images/Event1_2.jpg';
import Event1_3 from '../../Images/Event1_3.jpg';
import Event2Main from '../../Images/Event2Main.jpg';
import Event3Main from '../../Images/Event3Main.jpg';
import Event3_1 from '../../Images/Event3_1.jpg';
import Event3_2 from '../../Images/Event3_2.jpg';
import Event3_3 from '../../Images/Event3_3.jpg';
import Event4Main from '../../Images/Event4Main.jpg';
import Event4_1 from '../../Images/Event4_1.jpg';
import Event4_2 from '../../Images/Event4_2.jpg';
import Event5Main from '../../Images/Event5Main.jpg';
import Event5_1 from '../../Images/Event5_1.jpg';
import Event5_2 from '../../Images/Event5_2.jpg';
import Event5_3 from '../../Images/Event5_3.jpg';



export const EventsData = [
    {
        id: 1,
        title: "TM Sidhi Intensive Workshop",
        description: "This immersive program offered participants a unique opportunity to deepen their meditation practice, explore advanced techniques, and experience profound states of inner calm and expanded awareness. The camp fostered personal growth, mental clarity, and holistic well-being, leaving participants inspired and rejuvenated.",
        description1: "Expert Mentorship: Sessions led by certified TM instructors for personalized learning.",
        description2: "Group Meditation: Collective practices to amplify inner coherence and harmony.",
        description3: "Advanced TM Practices: Guided sessions on TM Sidhi techniques for achieving a profound state of awareness and bliss.",
        imageSrc: Event1Main,
        image1Src: Event1_1,
        image2Src: Event1_2,
        image3Src: Event1_3,
        slug: "Sidhi_Intensive_Workshop",
        location: "Assam"
    },
    {
        id: 2,
        title: "Blissful Synergy with Assamese Film Fraternity ",
        description: "The active involvement and heartfelt participation of members of the Assamese film fraternity in fostering a Coherent Collective Consciousness add a profound sense of bliss and euphoria to our shared journey.",
        description1: "Cultural Integration: Leveraging the influence of the Assamese film fraternity to inspire collective mindfulness.",
        description2: "Shared Meditation Practices: Collaborative sessions to strengthen unity and coherence.",
        description3: "Creative Synergy: Combining artistic vision with transcendental meditation for deeper impact.",
        imageSrc: Event2Main,
        image1Src: Event2Main,
        image2Src: Event2Main,
        image3Src: Event2Main,
        slug: "blissful_synergy_with_assamese_film_fraternity",
        location: "Assam"
    },
    {
        id: 3,
        title: "TM Students Workshop",
        description: "This enriching program was designed to introduce young minds to the powerful benefits of TM, fostering inner calm and enhanced focus in their daily lives.",
        description1: "Foundational TM Techniques: Step-by-step guidance on learning the effortless practice of TM.",
        description2: "Stress Management: Tools to reduce stress and anxiety, promoting mental well-being.",
        description3: "Focus & Creativity: Activities aimed at improving concentration, creativity, and academic performance.",
        imageSrc: Event3Main,
        image1Src: Event3_1,
        image2Src: Event3_2,
        image3Src: Event3_3,
        slug: "students",
        location: "Myanmar"
    },
    {
        id: 4,
        title: "TM Immersive Workshop",
        description: "This immersive program introduced participants to the profound benefits of TM, offering a transformative experience that nurtured inner peace and holistic well-being.",
        description1: "Stress Management: Practical tools to reduce stress and enhance mental clarity.",
        description2: "Guided Meditation: Expert-led sessions for profound relaxation and inner peace.",
        description3: "Community Engagement: Group meditations fostering collective coherence and shared growth.",
        imageSrc: Event4Main,
        image1Src: Event4_1,
        image2Src: Event4_2,
        image3Src: Event4Main,
        slug: "myanmar",
        location: "Myanmar"
    },
    {
        id: 5,
        title: "TM Students Workshop ",
        description: "This immersive program introduced participants to the profound benefits of TM, offering a transformative experience that nurtured inner peace and holistic well-being.",
        description1: "Stress Management: Practical tools to reduce stress and enhance mental clarity.",
        description2: "Guided Meditation: Expert-led sessions for profound relaxation and inner peace.",
        description3: "Community Engagement: Group meditations fostering collective coherence and shared growth.",
        imageSrc: Event5Main,
        image1Src: Event5_1,
        image2Src: Event5_2,
        image3Src: Event5_3,
        slug: "students",
        location: "Arunachal Pradesh"
    },
];

export default EventsData;