import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import offerings4 from '../../Images/offerings4.jpg';

const Public = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();


    return (
        // <div className="min-h-screen bg-[#191614] p-8">
        //     <div className="max-w-4xl mx-auto">
        //         <div className="bg-[#191614] border border-[#b58f49]/20 rounded-lg overflow-hidden">
        //             <div className="p-6 border-b border-[#b58f49]/20">
        //                 <button
        //                     onClick={() => navigate('/')}
        //                     className="text-[#b58f49] hover:text-[#c7a78a] transition-colors mb-4 flex items-center"
        //                 >
        //                     <span className="mr-2">←</span> Back to Home
        //                 </button>
        //                 <h1 className="text-3xl font-bold text-white">
        //                     Public Wellness
        //                 </h1>
        //             </div>
        //             <div className="p-6">
        //                 <div className="relative w-full h-64 mb-6 rounded-lg overflow-hidden">
        //                     <img
        //                         src={offerings4}
        //                         alt="Public Wellness"
        //                         className="w-full h-full object-cover"
        //                     />
        //                     <div className="absolute inset-0 bg-gradient-to-b from-transparent to-[#191614]/80" />
        //                 </div>
        //                 <div className="space-y-6 text-center text-white">
        //                     <h2 className="text-4xl font-bold text-[#b58f49] tracking-wider drop-shadow-lg">
        //                         COMING SOON
        //                     </h2>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>


        <div className="min-h-screen bg-[#191614] p-4 md:p-8">
            <div className="max-w-4xl mx-auto">
                <div className="bg-[#191614] border border-[#b58f49]/20 rounded-lg overflow-hidden">
                    <div className="p-4 md:p-6 border-b border-[#b58f49]/20">
                        <button
                            onClick={() => navigate('/')}
                            className="text-[#b58f49] hover:text-[#c7a78a] transition-colors mb-4 flex items-center"
                        >
                            <span className="mr-2">←</span> Back to Home
                        </button>
                        <h1 className="text-2xl md:text-3xl font-bold text-white">
                            Public Wellness
                        </h1>
                    </div>
                    <div className="p-4 md:p-6">
                        <div className="relative w-full h-48 md:h-64 mb-4 md:mb-6 rounded-lg overflow-hidden">
                            <img
                                src={offerings4}
                                alt="Educational Institutional Wellness"
                                className="w-full h-full object-cover"
                            />
                            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-[#191614]/80" />
                        </div>
                        <div className='flex justify-center'>
                            <h2 className="text-4xl font-bold text-[#b58f49] tracking-wider drop-shadow-lg">
                                COMING SOON
                            </h2>
                        </div>
                        <div className="space-y-4 my-4 md:space-y-6 bg-[#191614] text-white py-2 px-4 md:px-6 rounded-lg shadow-md">
                            <p className="text-gray-300 mb-4">
                                Our Public Wellness programs are designed for individuals seeking a holistic approach to well-being. We offer practical tools and techniques to improve mental and physical health, creating a more balanced lifestyle.
                            </p>

                            <h2 className="text-xl md:text-2xl font-semibold text-[#b58f49] mb-4">
                                Key Benefits
                            </h2>
                            <div className="grid grid-cols-1">
                                <div className="border border-[#b58f49]/60 rounded-xl p-4 md:p-6">
                                    <ul className="space-y-3">
                                        {[
                                            "Enhances mental clarity, focus, and emotional stability.",
                                            "Reduces everyday stress and fosters inner calm.",
                                            "Promotes holistic health, improving quality of life.",
                                            "Encourages mindfulness and a deeper connection to self."
                                        ].map((benefit, index) => (
                                            <li key={index} className="flex items-start">
                                                <span>{benefit}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Public;



