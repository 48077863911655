import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import EventsData from './EventsData';

const EventsDetail = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { slug } = useParams();
    const navigate = useNavigate();

    const event = EventsData.find(o => o.slug === slug);

    if (!event) {
        return (
            <div className="min-h-screen bg-[#191614] text-white p-4 sm:p-8">
                <div className="max-w-4xl mx-auto text-center">
                    <h1 className="text-2xl sm:text-4xl font-bold mb-6">Event Not Found</h1>
                    <button
                        onClick={() => navigate('/')}
                        className="px-4 sm:px-6 py-2 sm:py-3 bg-amber-700 hover:bg-amber-600 rounded-lg transition-colors"
                    >
                        Return to Home
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-[#191614] text-white">
            <div className="relative h-64 sm:h-80 md:h-96">
                <img
                    src={event.imageSrc}
                    alt={event.title}
                    className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-[#191614] to-transparent" />
                <div className="absolute inset-0 flex items-end">
                    <div className="max-w-6xl mx-auto w-full px-4 sm:px-6 lg:px-8 pb-8 sm:pb-12">
                        <button
                            onClick={() => navigate('/')}
                            className="text-[#b58f49] hover:text-amber-300 transition-colors mb-4 flex items-center text-sm sm:text-base"
                        >
                            <span className="mr-2">←</span> Back to Events
                        </button>
                        <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold">{event.title}</h1>
                    </div>
                </div>
            </div>

            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
                <div className="mb-8 sm:mb-16">
                    <h2 className="text-2xl sm:text-3xl font-bold mb-6 sm:mb-12 text-[#b58f49]">
                        Program Highlights
                    </h2>
                    <div className="prose prose-lg prose-invert max-w-none">
                        <p className="text-lg sm:text-xl text-gray-300 leading-relaxed">
                            {event.description}
                        </p>
                        <ul className="list-disc list-inside my-4 pl-3 sm:pl-5 space-y-2 text-base sm:text-lg text-gray-200">
                            <li>{event.description1}</li>
                            <li>{event.description2}</li>
                            <li>{event.description3}</li>
                        </ul>
                    </div>
                </div>

                <div className="mb-8 sm:mb-16">
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 lg:gap-8">
                        <div className="bg-[#191614] rounded-xl border border-amber-700/20">
                            <img
                                src={event.image1Src}
                                alt={`${event.title} highlight 1`}
                                className="w-full h-48 sm:h-56 lg:h-64 object-cover rounded-lg"
                            />
                        </div>
                        <div className="bg-[#191614] rounded-xl border border-amber-700/20">
                            <img
                                src={event.image2Src}
                                alt={`${event.title} highlight 2`}
                                className="w-full h-48 sm:h-56 lg:h-64 object-cover rounded-lg"
                            />
                        </div>
                        <div className="bg-[#191614] rounded-xl border border-amber-700/20">
                            <img
                                src={event.image3Src}
                                alt={`${event.title} highlight 3`}
                                className="w-full h-48 sm:h-56 lg:h-64 object-cover rounded-lg"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EventsDetail;