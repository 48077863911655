import React, { useState, useEffect, useRef } from 'react';
import { LuChevronLeft, LuChevronRight } from 'react-icons/lu';
import { FaUserCircle } from 'react-icons/fa';

function Testimonial() {
    const testimonials = [
        {
            name: "RAHUL HAZARIKA",
            title: "Doctor",
            text: "It had been an fantastic experience .",
            rating: 5
        },
        {
            name: "Ishani",
            title: "Author",
            text: "Life changing experience .",
            rating: 4
        },
        {
            name: "Shashhwati Goswami",
            title: "Movie Editor",
            // image: aboutTestimonial3,
            text: "We see him in our Guru Paduka pith...words are not enough for elaborate it...Heartiest Salute to you sir.",
            rating: 5
        },
        {
            name: "Chandana Biswas",
            title: "Educator and mental health counselor",
            // image: aboutTestimonial3,
            text: "He is a great mentor and guru for me who has enlightened my spritual direction.",
            rating: 5
        },
        {
            name: "Chandrarekha Mazumdar",
            title: "Tuition Teacher",
            // image: aboutTestimonial3,
            text: "Truly motivating.",
            rating: 5
        },
        {
            name: "Alma",
            title: "Advocate",
            // image: aboutTestimonial3,
            text: "Great experience.",
            rating: 5
        },
        {
            name: "Anusikha Choudhury",
            title: "Service holder",
            // image: aboutTestimonial3,
            text: "Awesome.",
            rating: 5
        },
    ];

    const [isPaused, setIsPaused] = useState(false);
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
    const containerRef = useRef(null);
    const minSwipeDistance = 100;
    const [itemWidth, setItemWidth] = useState(33.33);

    const items = [...testimonials, ...testimonials, ...testimonials, ...testimonials];

    const [state, setState] = useState({
        offset: -testimonials.length * itemWidth,
        transition: true,
    });

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth < 640) {
                setItemWidth(100);
            } else {
                setItemWidth(33.33);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const screenWidth = window.innerWidth;
        if (isPaused || screenWidth < 640) return;
        const interval = setInterval(() => {
            handleNext();
        }, 5000);
        return () => clearInterval(interval);
    }, [isPaused]);

    const handleTransitionEnd = () => {
        const currentOffset = Math.abs(state.offset / itemWidth);
        if (currentOffset <= testimonials.length) {
            setState({
                offset: -(testimonials.length * 2) * itemWidth,
                transition: false,
            });
        }
        else if (currentOffset >= testimonials.length * 3) {
            setState({
                offset: -(testimonials.length * 2) * itemWidth,
                transition: false,
            });
        }
    };

    const handlePrevious = () => {
        setState(prev => ({
            offset: prev.offset + itemWidth,
            transition: true,
        }));
    };

    const handleNext = () => {
        setState(prev => ({
            offset: prev.offset - itemWidth,
            transition: true,
        }));
    };

    const onTouchStart = (e) => {
        setTouchEnd(null);
        setTouchStart(e.targetTouches[0].clientX);
    };

    const onTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX);
    };

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;
        if (isLeftSwipe) {
            handleNext();
        }
        if (isRightSwipe) {
            handlePrevious();
        }
    };

    const getVisibleIndex = () => {
        const currentOffset = Math.abs(state.offset / itemWidth);
        return currentOffset % testimonials.length;
    };

    const NavigationButton = ({ direction, onClick }) => (
        <button
            onClick={onClick}
            className="absolute top-1/2 -translate-y-1/2 bg-[#181614]/60 text-[#b58f49] p-1.5 rounded-full hover:bg-[#b58f49] transition-colors duration-300 z-10"
            style={{ [direction]: '1rem' }}
        >
            {direction === 'left' ? (
                <LuChevronLeft className="w-6 h-6" />
            ) : (
                <LuChevronRight className="w-6 h-6" />
            )}
        </button>
    );

    const ProgressIndicators = () => (
        <div className="flex justify-center gap-2 mt-4">
            {testimonials.map((_, index) => (
                <button
                    key={index}
                    onClick={() => {
                        const newOffset = -(testimonials.length + index) * itemWidth;
                        setState({ offset: newOffset, transition: true });
                    }}
                    className={`w-2 h-2 rounded-full transition-all duration-300 
                        ${Math.floor(getVisibleIndex()) === index ? 'bg-[#b58f49] w-4' : 'bg-white/50 hover:bg-white/80'}`}
                />
            ))}
        </div>
    );

    const TestimonialCard = ({ testimonial }) => (
        <div className="bg-[#100f0e] p-6 px-12 rounded-xl shadow-lg transform transition-transform duration-500 hover:scale-105">
            <div className="flex items-center mb-4">
                {testimonial.image ? (
                    <img className="w-16 h-16 rounded-full mr-4" src={testimonial.image} alt={`${testimonial.name}`} />
                ) : (
                    <FaUserCircle className="w-16 h-16 font-extralight text-white mr-4" />
                )}
                <div>
                    <h3 className="text-xl font-bold text-[#b58f49]">{testimonial.name}</h3>
                    <p className="text-gray-300">{testimonial.title}</p>
                    {/* <div className="flex gap-1 my-1">
                        {Array.from({ length: testimonial.rating }).map((_, index) => (
                            <IoIosStarOutline key={index} className="text-sm text-[#b58f49]" />
                        ))}
                    </div> */}
                </div>
            </div>
            <p className="text-white">{testimonial.text}</p>
        </div>
    );


    return (
        <div>
            <div className="w-full px-3 md:px-8 pb-4 bg-[#191614] py-0 md:py-8">
                <div className="relative">
                    <div className="grid grid-cols-12 gap-4 items-center mb-8 px-3 ">
                        <div className="col-span-12 md:col-span-6 relative">
                            <div className="relative">
                                <h1 className="text-white text-3xl sm:text-4xl lg:text-5xl font-extrabold leading-tight text-center md:text-left">
                                    CLIENTS <span className="text-[#b58f49] relative">TESTIMONIALS</span>
                                </h1>
                            </div>
                        </div>
                        <div className="hidden md:block col-span-6 items-center justify-center">
                            <div className="w-full h-0.5 bg-gradient-to-r from-[#C7A78A]/20 via-[#b58f49] to-[#C7A78A]/20 relative">
                                <div className="absolute -right-2 -top-1 w-2 h-2 rounded-full bg-[#b58f49]" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative overflow-hidden">
                    <NavigationButton direction="left" onClick={handlePrevious} />
                    <NavigationButton direction="right" onClick={handleNext} />
                    <div
                        ref={containerRef}
                        className="flex"
                        style={{
                            transform: `translateX(${state.offset}%)`,
                            transition: state.transition ? 'transform 0.5s ease-in-out' : 'none',
                        }}
                        onTransitionEnd={handleTransitionEnd}
                        onTouchStart={onTouchStart}
                        onTouchMove={onTouchMove}
                        onTouchEnd={onTouchEnd}
                    >
                        {items.map((testimonial, index) => (
                            <div
                                key={`${testimonial.name}-${index}`}
                                className="flex-shrink-0 px-2"
                                onMouseEnter={() => setIsPaused(true)}
                                onMouseLeave={() => setIsPaused(false)}
                                style={{ width: `${itemWidth}%` }}
                            >
                                <TestimonialCard testimonial={testimonial} />
                            </div>
                        ))}
                    </div>
                </div>
                <ProgressIndicators />
            </div>
        </div>
    );
}

export default Testimonial;



