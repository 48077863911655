// import React, { useState,useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { GoArrowRight } from "react-icons/go";
// import EducationalForm from "../Contact/EducationalForm";
// import offerings2 from '../../Images/offerings2.jpg';

// const Educational = () => {
//     useEffect(() => {
//         window.scrollTo(0, 0);
//     }, []);

//     const navigate = useNavigate();
//     const [isModalOpen, setIsModalOpen] = useState(false);

//     const handleOpenModal = () => {
//         setIsModalOpen(true);
//     };

//     const handleCloseModal = () => {
//         setIsModalOpen(false);
//     };

//     return (
//         <div className="min-h-screen bg-[#191614] p-8">
//             <div className="max-w-4xl mx-auto">
//                 <div className="bg-[#191614] border border-[#b58f49]/20 rounded-lg overflow-hidden">
//                     <div className="p-6 border-b border-[#b58f49]/20">
//                         <button
//                             onClick={() => navigate('/')}
//                             className="text-[#b58f49] hover:text-[#c7a78a] transition-colors mb-4 flex items-center"
//                         >
//                             <span className="mr-2">←</span> Back to Home
//                         </button>
//                         <h1 className="text-3xl font-bold text-white">
//                             Educational Institutional Wellness
//                         </h1>
//                     </div>
//                     <div className="p-6">
//                         <div className="relative w-full h-64 mb-6 rounded-lg overflow-hidden">
//                             <img
//                                 src={offerings2}
//                                 alt="Educational Institutional Wellness"
//                                 className="w-full h-full object-cover"
//                             />
//                             <div className="absolute inset-0 bg-gradient-to-b from-transparent to-[#191614]/80" />
//                         </div>
//                         <div className='flex justify-center'>
//                             <button className="w-full my-4 sm:w-auto flex items-center justify-center space-x-4 bg-[#b58f49] text-black hover:text-white py-3 px-8 rounded-full font-medium transition-all duration-300 ease-in-out hover:translate-x-1 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-[#b58f49] focus:ring-opacity-50"
//                                 onClick={handleOpenModal}
//                             >
//                                 <span className="tracking-wider">BOOK A SESSION</span>
//                                 <GoArrowRight className="h-5 w-5" />
//                             </button>
//                         </div>
//                         <div className="space-y-6 bg-[#191614] text-white py-2 px-4 rounded-lg shadow-md">
//                             <p className="text-gray-300 mb-4">
//                                 We believe in nurturing young minds and academic professionals through transformative practices. Our Educational Institutional Wellness programs are tailored for students, teachers, and staff, promoting mental clarity, emotional stability, and holistic growth.
//                             </p>

//                             <h2 className="text-2xl font-semibold text-[#b58f49] mb-4">
//                                 Key Benefits
//                             </h2>
//                             <div className="grid md:grid-cols-2 gap-6">
//                                 <div className="border border-[#b58f49]/60 rounded-xl p-6">
//                                     <h3 className="text-xl font-semibold text-[#b58f49] mb-4">For Students</h3>
//                                     <ul className="space-y-3">
//                                         {[
//                                             "Reduces stress and anxiety, improving focus and academic performance.",
//                                             "Builds emotional resilience and promotes mental well-being.",
//                                             "Scientifically proven to enhance memory and creativity."
//                                         ].map((benefit, index) => (
//                                             <li key={index} className="flex items-start">
//                                                 <span className="mr-3 text-[#d8bd8b]">✓</span>
//                                                 <span>{benefit}</span>
//                                             </li>
//                                         ))}
//                                     </ul>
//                                 </div>
//                                 <div className="border border-[#b58f49]/60 rounded-xl p-6">
//                                     <h3 className="text-xl font-semibold text-[#b58f49] mb-4">For Academic Professionals</h3>
//                                     <ul className="space-y-3">
//                                         {[
//                                             "Supports mental clarity for better teaching outcomes.",
//                                             "Reduces burnout and promotes emotional balance.",
//                                             "Encourages a harmonious educational environment."
//                                         ].map((benefit, index) => (
//                                             <li key={index} className="flex items-start">
//                                                 <span className="mr-3 text-[#d8bd8b]">✓</span>
//                                                 <span>{benefit}</span>
//                                             </li>
//                                         ))}
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <EducationalForm
//                 isOpen={isModalOpen}
//                 onClose={handleCloseModal}
//                 className={`${isModalOpen ? 'opacity-100 visible' : 'opacity-0 invisible'} transition-opacity duration-500 ease-in-out`}
//             />
//         </div>
//     );
// };

// export default Educational;



import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoArrowRight } from "react-icons/go";
import EducationalForm from "../Contact/EducationalForm";
import offerings2 from '../../Images/offerings2.jpg';

const Educational = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="min-h-screen bg-[#191614] p-4 md:p-8">
            <div className="max-w-4xl mx-auto">
                <div className="bg-[#191614] border border-[#b58f49]/20 rounded-lg overflow-hidden">
                    <div className="p-4 md:p-6 border-b border-[#b58f49]/20">
                        <button
                            onClick={() => navigate('/')}
                            className="text-[#b58f49] hover:text-[#c7a78a] transition-colors mb-4 flex items-center"
                        >
                            <span className="mr-2">←</span> Back to Home
                        </button>
                        <h1 className="text-2xl md:text-3xl font-bold text-white">
                            Educational Institutional Wellness
                        </h1>
                    </div>
                    <div className="p-4 md:p-6">
                        <div className="relative w-full h-48 md:h-64 mb-4 md:mb-6 rounded-lg overflow-hidden">
                            <img
                                src={offerings2}
                                alt="Educational Institutional Wellness"
                                className="w-full h-full object-cover"
                            />
                            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-[#191614]/80" />
                        </div>
                        <div className='flex justify-center'>
                            <button className="w-full my-2 sm:w-auto flex items-center justify-center space-x-4 bg-[#b58f49] text-black hover:text-white py-3 px-8 rounded-full font-medium transition-all duration-300 ease-in-out hover:translate-x-1 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-[#b58f49] focus:ring-opacity-50"
                                onClick={handleOpenModal}
                            >
                                <span className="tracking-wider">BOOK A SESSION</span>
                                <GoArrowRight className="h-5 w-5" />
                            </button>
                        </div>
                        <div className="space-y-4 md:space-y-6 bg-[#191614] text-white py-2 px-4 md:px-6 rounded-lg shadow-md">
                            <p className="text-gray-300 mb-4">
                                We believe in nurturing young minds and academic professionals through transformative practices. Our Educational Institutional Wellness programs are tailored for students, teachers, and staff, promoting mental clarity, emotional stability, and holistic growth.
                            </p>

                            <h2 className="text-xl md:text-2xl font-semibold text-[#b58f49] mb-4">
                                Key Benefits
                            </h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
                                <div className="border border-[#b58f49]/60 rounded-xl p-4 md:p-6">
                                    <h3 className="text-lg md:text-xl font-semibold text-[#b58f49] mb-4">For Students</h3>
                                    <ul className="space-y-3">
                                        {[
                                            "Reduces stress and anxiety, improving focus and academic performance.",
                                            "Builds emotional resilience and promotes mental well-being.",
                                            "Scientifically proven to enhance memory and creativity."
                                        ].map((benefit, index) => (
                                            <li key={index} className="flex items-start">
                                                <span>{benefit}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="border border-[#b58f49]/60 rounded-xl p-4 md:p-6">
                                    <h3 className="text-lg md:text-xl font-semibold text-[#b58f49] mb-4">For Academic Professionals</h3>
                                    <ul className="space-y-3">
                                        {[
                                            "Supports mental clarity for better teaching outcomes.",
                                            "Reduces burnout and promotes emotional balance.",
                                            "Encourages a harmonious educational environment."
                                        ].map((benefit, index) => (
                                            <li key={index} className="flex items-start">
                                                <span>{benefit}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <EducationalForm
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                className={`${isModalOpen ? 'opacity-100 visible' : 'opacity-0 invisible'} transition-opacity duration-500 ease-in-out`}
            />
        </div>
    );
};

export default Educational;
