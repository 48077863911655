import React, { useState, useEffect } from 'react';
import bookingHeader from "../../Images/BrandingKit/mainBg.png";
import { LuX } from 'react-icons/lu';

const ContactModal = ({ isOpen, onClose }) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const formData = new FormData(event.target);
      const response = await fetch(
        'https://docs.google.com/forms/d/e/1FAIpQLSf7sxLFKsmCIIdj3CqIojJiEs-6M5wMCDOKa3qu_SOdrvq1ew/formResponse',
        {
          method: 'POST',
          body: formData,
          mode: 'no-cors'
        }
      );
      console.log(response);
      setIsSubmitted(true);
    } catch (error) {
      console.error('Error submitting form:', error);
      setIsLoading(false);
    }
  };

  const redirectToHome = () => {
    setIsSubmitted(false);
    onClose();
    window.location.href = '/';
  };

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
    >
      <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto relative">
        {!isSubmitted ? (
          <>
            <button onClick={onClose} className="absolute top-4 right-4 text-2xl text-gray-200 hover:text-gray-700 z-50">
              <LuX />
            </button>

            <div
              className="bg-cover bg-center w-full rounded-lg shadow-lg relative"
              style={{
                backgroundImage: `url(${bookingHeader})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top',
                backgroundColor: '#0c0b0a',
              }}
            >
              {isLoading}
              <div className="bg-black bg-opacity-50 p-8 rounded-t-lg">
                <h2 className="text-sm text-white uppercase tracking-widest">
                  Join the TM Journey
                </h2>
                <h1 className="text-4xl font-bold text-white mt-2">Book a Session</h1>
              </div>
              <div className="bg-[#b08a6f] p-8 rounded-b-lg">
                <form
                  className="space-y-6"
                  onSubmit={handleSubmit}
                  target="_blank"
                >
                  <div>
                    <label className="block text-sm font-bold text-black mb-2">
                      Full Name
                    </label>
                    <input
                      name="entry.1746166395"
                      type="text"
                      placeholder="Name"
                      autoComplete
                      required
                      className="w-full p-3 rounded-lg border border-white bg-transparent text-white placeholder-white"
                    />
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label className="block text-sm font-bold text-black mb-2">
                        Email Address
                      </label>
                      <input
                        name="entry.1817435118"
                        type="email"
                        placeholder="Email"
                        required
                        className="w-full p-3 rounded-lg border border-white bg-transparent text-white placeholder-white"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-bold text-black mb-2">
                        Phone Number
                      </label>
                      <input
                        name="entry.1736931708"
                        type="text"
                        placeholder="Phone Number"
                        required
                        className="w-full p-3 rounded-lg border border-white bg-transparent text-white placeholder-white"
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-bold text-black mb-2">
                      Preferred Session Type
                    </label>
                    <select
                      name="entry.185909966"
                      value={selectedOption}
                      onChange={handleOptionChange}
                      required
                      className="w-full p-3 rounded-lg border border-white bg-transparent text-white placeholder-white"
                    >
                      <option value="" disabled>Select Option</option>
                      <option className="bg-[#b08a6f]" value="Corporate Wellness">Corporate Wellness</option>
                      <option className="bg-[#b08a6f]" value="Educational Professional">Educational Professional</option>
                      <option className="bg-[#b08a6f]" value="Student">Student </option>
                      <option className="bg-[#b08a6f]" value="Healthcare Professional">Healthcare Professional</option>
                      <option className="bg-[#b08a6f]" value="Patient Care">Patient care </option>
                      {/* <option className="bg-[#b08a6f]" value="Public Wellness">Public Wellness</option> */}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-bold text-black mb-2">
                      Preferred Date
                    </label>
                    <input
                      name="entry.1055531754"
                      type="date"
                      required
                      className="w-full p-3 rounded-lg border border-white bg-transparent text-white placeholder-white"
                    />
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="w-full py-3 mt-4 bg-[#6b5b4b] text-white font-bold rounded-lg hover:bg-[#5a4a3a]"
                    >
                      {isLoading ? 'Submitting...' : 'Book a Session!'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </>
        ) : (
          <div className="p-8 text-center">
            <div className="mb-6">
              <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <svg className="w-8 h-8 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                </svg>
              </div>
              <h2 className="text-2xl font-bold text-gray-900 mb-2">Thank You!</h2>
              <p className="text-gray-600">
                Your session has been booked successfully. We'll contact you shortly to confirm your appointment.
              </p>
            </div>
            <button
              onClick={redirectToHome}
              className="bg-[#b58f49] text-white py-2 px-6 rounded-full hover:bg-[#8f6d35] transition-colors"
            >
              Return to Home
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactModal;