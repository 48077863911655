import React from 'react';
import { useNavigate } from 'react-router-dom';

const Association1 = () => {
    const navigate = useNavigate();
    return (
        <div className="bg-[#191614] text-white p-8 min-h-screen flex items-center justify-center">

            <div className="max-w-3xl text-center">
                <div className="max-w-6xl mx-auto w-full px-8 pb-12">
                    <button
                        onClick={() => navigate('/')}
                        className="text-[#b58f49] hover:text-amber-300 transition-colors mb-4 flex items-center"
                    >
                        <span className="mr-2">←</span> Back to Home
                    </button>
                </div>
                <h1 className="text-4xl lg:text-6xl font-extrabold leading-tight mb-8">
                    SPIRITUAL  <span className="text-[#b58f49]">REGENARATION </span> TRUST
                </h1>
                <p className="mb-4">
                    At JeevaJyoti, we take immense pride in our association with the esteemed Spiritual Regeneration Trust, a pioneer in promoting the profound practice of Transcendental Meditation (TM). This collaboration embodies our shared vision of spreading the light of TM across the region, empowering individuals and communities to experience its transformative benefits.
                </p>
                <p className="mb-4">
                    Through this partnership, we aim to enlighten minds and foster a culture of inner peace, balance, and holistic well-being. By aligning with the values of this renowned trust, we ensure that our efforts adhere to the highest standards of authenticity and respect for the TM tradition.
                </p>
                <p className="mb-4">
                    Together, we are committed to nurturing a deeper understanding of TM’s unparalleled ability to reduce stress, enhance clarity, and unlock human potential. This association strengthens our resolve to bring the radiance of TM to every corner of the region, paving the way for a brighter, more harmonious future.
                </p>
                <p >
                    Join us in this journey of transformation and unity, as we continue to spread the timeless wisdom of TM in its truest essence.
                </p>
            </div>
        </div>
    );
};

export default Association1;
