import React, { useState, useEffect, useRef } from 'react';
import DiscoverImg1 from '../Images/11.jpg';
import DiscoverImg2 from '../Images/22.jpg';
import DiscoverImg3 from '../Images/33.jpg';
import Tmvideo from '../Images/Tm Video.mp4';

import { FaDotCircle } from 'react-icons/fa';

const DiscoverTM = ({ height, className }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const ref = useRef(null);
    const videoRef = useRef(null);

    useEffect(() => {
        const videoElement = videoRef.current;
        if (!videoElement) return;

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        videoElement.muted = false;
                        videoElement.play().catch(error => {
                            console.error("Error attempting to play video:", error);
                        });
                    } else {
                        videoElement.pause();
                    }
                });
            },
            { threshold: 0.5 }
        );
        observer.observe(videoElement);
        return () => {
            observer.unobserve(videoElement);
        };
    }, []);


    const carouselData = [
        {
            id: 1,
            image: DiscoverImg1,
            description: "Enhance your resilience to stress and navigate challenging situations with greater calmness. Studies indicate that the Transcendental Meditation (TM) technique can also be beneficial for managing PTSD and ADHD.",
            title: "Reduce Stress & Anxiety"
        },
        {
            id: 2,
            image: DiscoverImg2,
            description: "Experience improved brain function that leads to a wider perspective, sharper focus, and heightened creativity, helping you achieve your goals more effectively.",
            title: "Increase Mental Clarity & Creativity"
        },
        {
            id: 3,
            image: DiscoverImg3,
            description: "Benefit from deep, restorative rest that activates the body's natural healing processes, supporting better heart health, stronger immunity, improved sleep, and relief from stress-related issues.",
            title: "Get Better Sleep & Health"
        }
    ];

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentIndex((prevIndex) =>
                prevIndex === carouselData.length - 1 ? 0 : prevIndex + 1
            );
        }, 5000);
        return () => clearInterval(timer);
    }, []);

    return (
        <div className="Types py-10 md:py-14 bg-[#191614]">
            <div className="w-full px-4 sm:px-6 md:px-10 lg:px-16">
                <div className="relative">
                    <div className="grid grid-cols-12 gap-4 items-center mb-8 md:mb-16">
                        <div className="col-span-12 md:col-span-6 relative mb-4 md:mb-0">
                            <div className="relative">
                                <h1 className="text-white text-3xl sm:text-4xl lg:text-5xl font-extrabold leading-tight text-center md:text-left">
                                    DISCOVER<span className="text-[#b58f49] relative"> TM</span>
                                </h1>
                            </div>
                        </div>
                        <div className="hidden md:block col-span-6 items-center justify-center">
                            <div className="w-full h-0.5 bg-gradient-to-r from-[#C7A78A]/20 via-[#b58f49] to-[#C7A78A]/20 relative">
                                <div className="absolute -right-2 -top-1 w-2 h-2 rounded-full bg-[#b58f49]" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-4 mt-6 md:mt-10">
                    <div ref={ref} className="col-span-12 md:col-span-6 bg-[#100F0E] p-6 md:p-10 rounded-xl transition-transform duration-1000 hover:rotate-y-30deg">
                        <h1 className="text-[#b58f49] text-xl md:text-xl font-extrabold">What is Transcendental Meditation (TM)?</h1>
                        <p className="text-white text-md mt-4 md:mt-7">
                            TM Transcendental Meditation is a unique, effortless technique that brings profound benefits to your mind and body. By allowing your mind to settle into a state of restful awareness, TM naturally dissolves stress, revitalizes energy, and promotes inner peace. This practice is backed by extensive scientific research, highlighting its effectiveness in reducing anxiety, improving focus, and enhancing overall well-being.
                        </p>
                    </div>
                    <div className="col-span-12 md:col-span-6">
                        <video
                            ref={videoRef}
                            width="100%"
                            height={height}
                            src={Tmvideo}
                            className="rounded-xl"
                            controls
                            controlsList="nodownload"
                            muted
                        />
                        <p className='text-[#b58f49] text-[10px] font-thin italic'>
                            Video courtesy : <a href="https://youtu.be/utpqeiSYRUw?si=Nnev-VlR0Fi-aOmr" target='_blank' rel="noreferrer"> Transcendental Meditation Youtube </a>
                        </p>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-4 mt-6 md:mt-10">
                    <div className="col-span-12 md:col-span-4 type-block bg-[#100F0E] p-6 md:p-10 rounded-xl transition-transform duration-1000 hover:rotate-y-30deg mb-4 md:mb-0">
                        <h1 className="text-[#b58f49] text-xl md:text-2xl font-extrabold">Psychological Benefits</h1>
                        <ul className="text-white text-md mt-4 md:mt-7 space-y-2 md:space-y-4">
                            <li className="flex items-center">
                                <FaDotCircle className="text-xl text-[#b58f49] mr-2" />
                                Scientifically Backed: Proven by research from leading institutions
                            </li>
                            <li className="flex items-center">
                                <FaDotCircle className="text-xl text-[#b58f49] mr-2" />
                                Clarity & Calm: Boosts mental clarity and promotes inner peace
                            </li>
                            <li className="flex items-center">
                                <FaDotCircle className="text-md text-[#b58f49] mr-2" />
                                Boost Creativity: Sharpens memory and focus
                            </li>
                            <li className="flex items-center">
                                <FaDotCircle className=" text-xl text-[#b58f49] mr-2" />
                                Holistic Wellness: A simple practice that transforms mind and life
                            </li>
                        </ul>
                    </div>
                    <div className="col-span-12 md:col-span-4 relative overflow-hidden group rounded-xl mb-4 md:mb-0">
                        <div className="relative h-64 md:h-full">
                            {carouselData.map((item, index) => (
                                <div
                                    key={item.id}
                                    className={`absolute w-full h-full transition-opacity duration-500 ${index === currentIndex ? 'opacity-100' : 'opacity-0'
                                        }`}
                                >
                                    <img
                                        src={item.image}
                                        alt={item.title}
                                        className="w-full h-full object-cover "
                                    />
                                    <div className="absolute inset-0 bg-black bg-opacity-80 opacity-100 flex flex-col justify-center items-center text-white p-4">
                                        <h3 className="text-lg md:text-xl font-bold mb-2 text-center text-[#b58f49]">{item.title}</h3>
                                        <p className="text-xs md:text-sm text-center">{item.description}</p>
                                    </div>
                                </div>
                            ))}
                            <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
                                {carouselData.map((_, index) => (
                                    <button
                                        key={index}
                                        className={`w-2 h-2 rounded-full transition-colors duration-300 ${index === currentIndex ? 'bg-white' : 'bg-gray-400'
                                            }`}
                                        onClick={() => setCurrentIndex(index)}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 md:col-span-4 type-block bg-[#100F0E] p-6 md:p-10 rounded-xl transition-transform duration-1000 hover:rotate-y-30deg">
                        <h1 className="text-[#b58f49] text-xl md:text-2xl font-extrabold">Global Significance</h1>
                        <ul className="text-white text-md mt-4 md:mt-7 space-y-2 md:space-y-4">
                            <li className="flex items-center">
                                <FaDotCircle className="text-xl text-[#b58f49] mr-2" />
                                Worldwide Adoption: Practiced by millions across diverse fields.
                            </li>
                            <li className="flex items-center">
                                <FaDotCircle className="text-xl text-[#b58f49] mr-2" />
                                Transformative Impact: Enhancing personal and professional lives globally.
                            </li>
                            <li className="flex items-center">
                                <FaDotCircle className="text-lg text-[#b58f49] mr-2" />
                                Cultural Acceptance: Valued by spiritual leaders and professionals.
                            </li>
                            <li className="flex items-center">
                                <FaDotCircle className=" text-xl text-[#b58f49] mr-2" />
                                Timeless Appeal: A practice reshaping lives around the world.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DiscoverTM;
