import React from 'react'
import banner1 from '../Images/Banner.jpg';
import { GoArrowRight } from "react-icons/go";
import { Link } from 'react-router-dom';

function Banner({ handleOpenModal }) {
    return (
        <div className="relative bg-[#191614] flex items-center justify-center px-2 sm:px-4 md:px-8 lg:px-8 py-8 md:py-8">
            <div className="absolute inset-0 overflow-hidden pointer-events-none">
                <div className="absolute top-0 left-0 w-64 sm:w-80 md:w-96 h-64 sm:h-80 md:h-96 bg-[#b58f49]/5 rounded-full blur-3xl" />
                <div className="absolute bottom-0 right-0 w-64 sm:w-80 md:w-96 h-64 sm:h-80 md:h-96 bg-[#b58f49]/5 rounded-full blur-3xl" />
            </div>
            <div className="relative w-full max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <div className="relative rounded-2xl sm:rounded-3xl overflow-hidden shadow-xl">
                    <img
                        src={banner1}
                        alt="People practicing yoga in a studio"
                        className="w-full h-[50vh] sm:h-[60vh] opacity-60 md:h-[70vh] lg:h-[80vh] object-cover transform scale-105 hover:scale-100 transition-transform duration-700 ease-out"
                    />
                    <div className="absolute inset-0 bg-gradient-to-br from-[#191614]/70 via-[#191614]/50 to-transparent" />
                    <div className="absolute inset-0 flex items-center justify-center text-center px-4 sm:px-6 md:px-12">
                        <div className="space-y-4 max-w-4xl px-4">
                            <h1 className="text-white text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-bold leading-tight tracking-tight">
                                THE PATH TO {' '}
                                <span className="relative inline-block">
                                    <span className="text-[#b58f49]">INNER HARMONY</span>
                                    <span className="absolute -bottom-1 sm:-bottom-2 left-0 w-full h-0.5 bg-gradient-to-r from-transparent via-[#b58f49] to-transparent" />
                                </span>
                            </h1>
                            <p className="text-gray-300 text-base sm:text-lg md:text-xl font-light tracking-wide">
                                Join the <span className="text-[#b58f49] font-medium">JeevaJyoti </span>Mission
                            </p>
                            <div className="mt-6 sm:mt-8 md:mt-12 flex justify-center">
                                <Link to='/partner'>
                                    <button className="w-full sm:w-auto flex items-center mb-6 justify-center space-x-4 bg-[#b58f49] text-black hover:text-white py-3 px-8 rounded-full font-medium transition-all duration-300 ease-in-out hover:translate-x-1 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-[#b58f49] focus:ring-opacity-50">
                                        <span className="relative z-10 tracking-wider sm:tracking-widest">PARTNER WITH US</span>
                                        <GoArrowRight className="h-4 w-4 sm:h-5 sm:w-5" />
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner