import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import ReactGA from 'react-ga';
import App from './App';


// // Initialize Google Analytics
// ReactGA.initialize('G-VSV4H33RSG'); // Replace with your Tracking ID

// // Log the initial page load
// ReactGA.pageview(window.location.pathname + window.location.search);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);