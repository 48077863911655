import React from 'react';
import mainLogo from '../Images/BrandingKit/mainLogo.png';

const About = () => {
    return (
        <div className="bg-black text-white py-8 md:py-10">
            <div className="w-full px-4 md:px-16">
                <div className="grid grid-cols-1 md:grid-cols-3 justify-between space-y-8 md:space-y-0 items-center">
                    <div className="text-center md:text-left w-full md:w-auto">
                        <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-white mb-2">
                            OUR
                        </h1>
                        <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-[#b58f49] mb-6">
                            MISSION
                        </h2>
                    </div>
                    <div className="w-full max-w-sm text-center md:text-center ">
                        <p className="text-sm md:text-base leading-relaxed">
                            At JeevaJyoti, we guide individuals and communities toward holistic well-being through transformative yogic practices and transcendental meditation, fostering inner peace and self-realization.
                        </p>
                    </div>
                    <div className="w-full md:max-w-sm flex items-center justify-center md:justify-end">
                        <img
                            alt="Person practicing yoga"
                            className="w-[150px] h-full object-cover rounded-lg shadow-lg"
                            src={mainLogo}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;