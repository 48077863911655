import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LuChevronLeft, LuChevronRight, LuMapPin } from 'react-icons/lu';
import { EventsData } from './Events/EventsData';

const Events = () => {
    const navigate = useNavigate();
    const [isPaused, setIsPaused] = useState(false);
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
    const containerRef = useRef(null);
    const minSwipeDistance = 100;
    const [itemWidth, setItemWidth] = useState(25);

    const items = [...EventsData, ...EventsData, ...EventsData, ...EventsData];

    const [state, setState] = useState({
        offset: -EventsData.length * itemWidth,
        transition: true,
    });

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth < 640) {
                setItemWidth(100);
            } else {
                setItemWidth(25);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const screenWidth = window.innerWidth;
        if (isPaused || screenWidth < 640) return;
        const interval = setInterval(() => {
            handleNext();
        }, 10000);
        return () => clearInterval(interval);
    }, [isPaused]);

    const handleTransitionEnd = () => {
        const currentOffset = Math.abs(state.offset / itemWidth);
        if (currentOffset <= EventsData.length) {
            setState({
                offset: -(EventsData.length * 2) * itemWidth,
                transition: false,
            });
        } else if (currentOffset >= EventsData.length * 3) {
            setState({
                offset: -(EventsData.length * 2) * itemWidth,
                transition: false,
            });
        }
    };

    const handlePrevious = () => {
        setState(prev => ({
            offset: prev.offset + itemWidth,
            transition: true,
        }));
    };

    const handleNext = () => {
        setState(prev => ({
            offset: prev.offset - itemWidth,
            transition: true,
        }));
    };

    const onTouchStart = (e) => {
        setTouchEnd(null);
        setTouchStart(e.targetTouches[0].clientX);
    };

    const onTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientX);
    };

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;
        if (isLeftSwipe) {
            handleNext();
        }
        if (isRightSwipe) {
            handlePrevious();
        }
    };

    const getVisibleIndex = () => {
        const currentOffset = Math.abs(state.offset / itemWidth);
        return currentOffset % EventsData.length;
    };

    const NavigationButton = ({ direction, onClick }) => (
        <button
            onClick={onClick}
            className="absolute top-1/2 -translate-y-1/2 bg-[#191614]/80 text-white p-2 rounded-full hover:bg-[#b58f49] transition-colors duration-300 z-10"
            style={{ [direction]: '1rem' }}
        >
            {direction === 'left' ? (
                <LuChevronLeft className="w-6 h-6" />
            ) : (
                <LuChevronRight className="w-6 h-6" />
            )}
        </button>
    );

    const ProgressIndicators = () => (
        <div className="flex justify-center gap-2 mt-4">
            {EventsData.map((_, index) => (
                <button
                    key={index}
                    onClick={() => {
                        const newOffset = -(EventsData.length + index) * itemWidth;
                        setState({ offset: newOffset, transition: true });
                    }}
                    className={`w-2 h-2 rounded-full transition-all duration-500 
                        ${Math.floor(getVisibleIndex()) === index ? 'bg-[#b58f49] w-4' : 'bg-white/50 hover:bg-white/80'}`}
                />
            ))}
        </div>
    );

    const EventsCard = ({ title, description, imageSrc, date, time, location, onClick }) => {
        const [isHovered, setIsHovered] = useState(false);
        return (
            <div
                className="w-full sm:w-1/2 md:w-1/4 flex-shrink-0 group cursor-pointer px-4 "
                onMouseEnter={() => {
                    setIsHovered(true);
                    setIsPaused(true);
                }}
                onMouseLeave={() => {
                    setIsHovered(false);
                    setIsPaused(false);
                }}
                onClick={onClick}
            >
                <div className="relative overflow-hidden rounded-2xl  transition-all duration-300 h-[400px] bg-[#1E1B17]">
                    <div className="h-1/2 relative overflow-hidden">
                        <img
                            src={imageSrc}
                            alt={`${title} - Event`}
                            className="w-full h-full object-cover object-center transform transition-transform duration-500 group-hover:scale-110"
                        />
                        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-[#1E1B17]" />
                    </div>
                    <div className="relative p-6 h-1/2 flex flex-col">
                        <div className="mb-3">
                            <h3 className="text-xl text-center font-bold text-white group-hover:text-[#b58f49] transition-colors duration-300">
                                {title}
                            </h3>
                        </div>
                        <div className="space-y-2 mb-4 ">
                            <div className="flex items-center text-gray-400 text-sm mt-6">
                                <LuMapPin className="w-4 h-4 mr-2 text-[#b58f49]" />
                                <span>{location}</span>
                            </div>
                        </div>
                        <div className="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-transparent via-[#b58f49] to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="bg-[#191614] py-8" id="offerings">
            <div className="w-full px-8 md:px-16">
                <div className="relative">
                    <div className="grid grid-cols-12 gap-4 items-center mb-8 sm:mb-12 lg:mb-16">
                        <div className="col-span-12 md:col-span-6 relative">
                            <div className="relative">
                                <h1 className="text-white text-3xl sm:text-4xl lg:text-5xl font-extrabold leading-tight text-center md:text-left">
                                    EARLIER <span className="text-[#b58f49] relative">EVENTS</span>
                                </h1>
                            </div>
                        </div>
                        <div className="hidden md:block col-span-6 items-center justify-center">
                            <div className="w-full h-0.5 bg-gradient-to-r from-[#C7A78A]/20 via-[#b58f49] to-[#C7A78A]/20 relative">
                                <div className="absolute -right-2 -top-1 w-2 h-2 rounded-full bg-[#b58f49]" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative overflow-hidden">
                    <NavigationButton direction="left" onClick={handlePrevious} />
                    <NavigationButton direction="right" onClick={handleNext} />
                    <div
                        ref={containerRef}
                        className="flex"
                        style={{
                            transform: `translateX(${state.offset}%)`,
                            transition: state.transition ? 'transform 0.5s ease-in-out' : 'none',
                        }}
                        onTransitionEnd={handleTransitionEnd}
                        onTouchStart={onTouchStart}
                        onTouchMove={onTouchMove}
                        onTouchEnd={onTouchEnd}
                    >
                        {items.map((events, index) => (
                            <EventsCard
                                key={index}
                                title={events.title}
                                imageSrc={events.imageSrc}
                                date={events.date}
                                time={events.time}
                                location={events.location}
                                onClick={() => navigate(`/events/${events.slug}`)}
                            />
                        ))}
                    </div>
                </div>
                <ProgressIndicators />
            </div>
        </div>
    );
};

export default Events;



// import React, { useState, useEffect, useRef } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { LuChevronLeft, LuChevronRight, LuMapPin } from 'react-icons/lu';
// import { EventsData } from './Events/EventsData';

// const Events = () => {
//     const navigate = useNavigate();
//     const [isPaused, setIsPaused] = useState(false);
//     const [touchStart, setTouchStart] = useState(null);
//     const [touchEnd, setTouchEnd] = useState(null);
//     const containerRef = useRef(null);
//     const minSwipeDistance = 50; // Adjusted the swipe distance for better sensitivity
//     const [itemWidth, setItemWidth] = useState(25);
//     const [isMobile, setIsMobile] = useState(false);

//     const items = [...EventsData, ...EventsData, ...EventsData, ...EventsData];

//     const [state, setState] = useState({
//         offset: -EventsData.length * itemWidth,
//         transition: true,
//     });

//     useEffect(() => {
//         const handleResize = () => {
//             const screenWidth = window.innerWidth;
//             setIsMobile(screenWidth < 640);
//             if (screenWidth < 640) {
//                 setItemWidth(100);
//             } else {
//                 setItemWidth(25);
//             }
//         };
//         handleResize();
//         window.addEventListener('resize', handleResize);
//         return () => {
//             window.removeEventListener('resize', handleResize);
//         };
//     }, []);

//     useEffect(() => {
//         if (isPaused || isMobile) return;
//         const interval = setInterval(() => {
//             handleNext();
//         }, 10000);
//         return () => clearInterval(interval);
//     }, [isPaused, isMobile]);

//     const handleTransitionEnd = () => {
//         const currentOffset = Math.abs(state.offset / itemWidth);
//         if (currentOffset <= EventsData.length) {
//             setState({
//                 offset: -(EventsData.length * 2) * itemWidth,
//                 transition: false,
//             });
//         } else if (currentOffset >= EventsData.length * 3) {
//             setState({
//                 offset: -(EventsData.length * 2) * itemWidth,
//                 transition: false,
//             });
//         }
//     };

//     const handlePrevious = () => {
//         setState(prev => ({
//             offset: prev.offset + itemWidth,
//             transition: true,
//         }));
//     };

//     const handleNext = () => {
//         setState(prev => ({
//             offset: prev.offset - itemWidth,
//             transition: true,
//         }));
//     };

//     const onTouchStart = (e) => {
//         setTouchEnd(null);
//         setTouchStart(e.targetTouches[0].clientX);
//     };

//     const onTouchMove = (e) => {
//         setTouchEnd(e.targetTouches[0].clientX);
//     };

//     const onTouchEnd = () => {
//         if (!touchStart || !touchEnd) return;
//         const distance = touchStart - touchEnd;
//         const isLeftSwipe = distance > minSwipeDistance;
//         const isRightSwipe = distance < -minSwipeDistance;
//         if (isLeftSwipe) {
//             handleNext();
//         }
//         if (isRightSwipe) {
//             handlePrevious();
//         }
//     };

//     const getVisibleIndex = () => {
//         const currentOffset = Math.abs(state.offset / itemWidth);
//         return currentOffset % EventsData.length;
//     };

//     const NavigationButton = ({ direction, onClick }) => (
//         <button
//             onClick={onClick}
//             className={`absolute top-1/2 -translate-y-1/2 bg-[#191614]/80 text-white p-2 rounded-full hover:bg-[#b58f49] transition-colors duration-300 z-10 ${isMobile ? 'hidden' : ''}`}
//             style={{ [direction]: '1rem' }}
//         >
//             {direction === 'left' ? (
//                 <LuChevronLeft className="w-6 h-6" />
//             ) : (
//                 <LuChevronRight className="w-6 h-6" />
//             )}
//         </button>
//     );

//     const ProgressIndicators = () => (
//         <div className="flex justify-center gap-2 mt-4">
//             {EventsData.map((_, index) => (
//                 <button
//                     key={index}
//                     onClick={() => {
//                         const newOffset = -(EventsData.length + index) * itemWidth;
//                         setState({ offset: newOffset, transition: true });
//                     }}
//                     className={`w-2 h-2 rounded-full transition-all duration-500 
//                         ${Math.floor(getVisibleIndex()) === index ? 'bg-[#b58f49] w-4' : 'bg-white/50 hover:bg-white/80'}`}
//                 />
//             ))}
//         </div>
//     );

//     const EventsCard = ({ title, description, imageSrc, date, time, location, onClick }) => {
//         const [isHovered, setIsHovered] = useState(false);
//         return (
//             <div
//                 className="w-full sm:w-1/2 md:w-1/4 flex-shrink-0 group cursor-pointer px-4"
//                 onMouseEnter={() => {
//                     setIsHovered(true);
//                     setIsPaused(true);
//                 }}
//                 onMouseLeave={() => {
//                     setIsHovered(false);
//                     setIsPaused(false);
//                 }}
//                 onClick={onClick}
//             >
//                 <div className="relative overflow-hidden rounded-2xl transition-all duration-300 h-[400px] bg-[#1E1B17]">
//                     <div className="h-1/2 relative overflow-hidden">
//                         <img
//                             src={imageSrc}
//                             alt={`${title} - Event`}
//                             className="w-full h-full object-cover object-center transform transition-transform duration-500 group-hover:scale-110"
//                         />
//                         <div className="absolute inset-0 bg-gradient-to-b from-transparent to-[#1E1B17]" />
//                     </div>
//                     <div className="relative p-6 h-1/2 flex flex-col">
//                         <div className="mb-3">
//                             <h3 className="text-xl text-center font-bold text-white group-hover:text-[#b58f49] transition-colors duration-300">
//                                 {title}
//                             </h3>
//                         </div>
//                         <div className="space-y-2 mb-4">
//                             <div className="flex items-center text-gray-400 text-sm mt-6">
//                                 <LuMapPin className="w-4 h-4 mr-2 text-[#b58f49]" />
//                                 <span>{location}</span>
//                             </div>
//                         </div>
//                         <div className="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-transparent via-[#b58f49] to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
//                     </div>
//                 </div>
//             </div>
//         );
//     };

//     return (
//         <div className="bg-[#191614] py-8" id="offerings">
//             <div className="w-full px-8 md:px-16">
//                 <div className="relative">
//                     <div className="grid grid-cols-12 gap-4 items-center mb-8 sm:mb-12 lg:mb-16">
//                         <div className="col-span-12 md:col-span-6 relative">
//                             <div className="relative">
//                                 <h1 className="text-white text-3xl sm:text-4xl lg:text-5xl font-extrabold leading-tight text-center md:text-left">
//                                     EARLIER <span className="text-[#b58f49] relative">EVENTS</span>
//                                 </h1>
//                             </div>
//                         </div>
//                         <div className="hidden md:block col-span-6 items-center justify-center">
//                             <div className="w-full h-0.5 bg-gradient-to-r from-[#C7A78A]/20 via-[#b58f49] to-[#C7A78A]/20 relative">
//                                 <div className="absolute -right-2 -top-1 w-2 h-2 rounded-full bg-[#b58f49]" />
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="relative overflow-hidden">
//                     {!isMobile && <NavigationButton direction="left" onClick={handlePrevious} />}
//                     {!isMobile && <NavigationButton direction="right" onClick={handleNext} />}
//                     <div
//                         ref={containerRef}
//                         className="flex"
//                         style={{
//                             transform: `translateX(${state.offset}%)`,
//                             transition: state.transition ? 'transform 0.5s ease-in-out' : 'none',
//                         }}
//                         onTransitionEnd={handleTransitionEnd}
//                         onTouchStart={onTouchStart}
//                         onTouchMove={onTouchMove}
//                         onTouchEnd={onTouchEnd}
//                     >
//                         {items.map((events, index) => (
//                             <EventsCard
//                                 key={index}
//                                 title={events.title}
//                                 imageSrc={events.imageSrc}
//                                 date={events.date}
//                                 time={events.time}
//                                 location={events.location}
//                                 onClick={() => navigate(`/events/${events.slug}`)}
//                             />
//                         ))}
//                     </div>
//                 </div>
//                 <ProgressIndicators />
//             </div>
//         </div>
//     );
// };

// export default Events;
